// config.js
const config = {
    // apiUrl: 'http://localhost:3001',
    apiUrl: 'https://cargo-almaty99.kz:3001',
    
    nameCargo: 'CARGO_ALMATY99'
  };
  
  
export default config;
  